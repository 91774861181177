<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            :range="true"
            label="TBM기간"
            name="period"
            v-model="period"
          />
        </div>
      </template>
    </c-search-box>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-table
          ref="table"
          title="TBM 목록"
          :columns="grid.columns"
          :gridHeight="grid.height"
          :data="grid.data"
          :selection="popupParam.type"
          rowKey="tbmId"
          @linkClick="linkClick"
          @rowDblclick="rowDblclick"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn label="검색" icon="search" @btnClicked="getList" />
              <c-btn label="선택" icon="check" @btnClicked="select" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'tbm-pop',
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          type: 'single',
          plantCd: '',
        }
      },
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    }
  },
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            style: 'width:80px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'tbmNo',
            field: 'tbmNo',
            label: 'TBM번호',
            style: 'width:100px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'tbmName',
            field: 'tbmName',
            label: 'TBM명',
            align: 'left',
            style: 'width:200px',
            type: 'link',
            sortable: true,
          },
          {
            name: 'workSummary',
            field: 'workSummary',
            label: '작업허가서',
            style: 'width:200px',
            align: 'left',
            type: 'link',
            sortable: true,
          },
          // {
          //   name: 'tbmCompleteFlagName',
          //   field: 'tbmCompleteFlagName',
          //   label: '작성상태',
          //   style: 'width:80px',
          //   align: 'center',
          //   sortable: true,
          // },
          {
            name: 'tbmLocation',
            field: 'tbmLocation',
            label: '장소',
            style: 'width:80px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'tbmDate',
            field: 'tbmDate',
            label: 'TBM일자',
            style: 'width:80px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'workTime',
            field: 'workTime',
            label: 'TBM시간',
            style: 'width:80px',
            align: 'center',
            sortable: true,
          },
          // {
          //   name: 'processName',
          //   field: 'processName',
          //   style: 'width:80px',
          //   label: '공정',
          //   align: 'center',
          //   sortable: true,
          // },
          {
            name: 'vendorName',
            field: 'vendorName',
            style: 'width:80px',
            label: '업체',
            align: 'center',
            sortable: true,
          },
          {
            name: 'tbmLeaderName',
            field: 'tbmLeaderName',
            label: 'TBM리더',
            style: 'width:80px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'permitTypeName',
            field: 'permitTypeName',
            label: '허가서구분',
            style: 'width:80px',
            align: 'center',
            sortable: true,
          },
        ],
        data: [],
        height: '500px'
      },
      period: ['', ''],
      searchParam: {
        startYmd: '',
        endYmd: '',
        plantCd: '',
        processCd: '',
      },
      editable: true,
      listUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        // isFull: true,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.sai.tbm.list.url;
      this.getList();
    },
    getList() {
      if (this.period) {
        this.searchParam.startYmd = this.period[0];
        this.searchParam.endYmd = this.period[1];
      } else {
        this.searchParam.startYmd = '';
        this.searchParam.endYmd = '';
      }
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data.map(item=>{
          return {
            ...item,
            workSummary: item.permitNo + " | " + item.workSummary
          }
        })
      },);
    },
    linkClick(result) {
      this.popupOptions.target = () =>
      import(`${"./tbmDetail.vue"}`);
      this.popupOptions.title = 'TBM 상세';
      this.popupOptions.param = {
        tbmId: result ? result.tbmId : '',
      };
      this.popupOptions.isFull = true;
      // this.popupOptions.width = '95%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
    select() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: 'TBM을 지정하세요.', // TBM을 지정하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$emit('closePopup', selectData);
      }
    },
    rowDblclick(row) {
      if (this.popupParam.type === 'single') {
        this.$emit('closePopup', [row]);
      }
    },
  }
};
</script>
